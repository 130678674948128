import * as React from "react"

const FooterLayout = () => {


    return (

        <footer className="mt-20 mb-10 ml-16 mr-16 color-grey text-xs tracking-wide opacity-70">
            © {new Date().getFullYear()} Mary & Maurizio photography - Made with
            {` `}
            <a href="https://www.gatsbyjs.com">Gatsby</a>
          </footer>

        )
    }
    
    export default FooterLayout