import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import MenuIndex from "./menu"
import Logo from "../images/logo_MM.inline.svg";


const HeaderLayout = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            behance
            instagram
          }
        }
      }
    `
  )
  
  const { title } = data.site.siteMetadata;
  return (
    <header className="grid grid-cols-2 h-32">
      
      <div className="col_Logo flex items-center">
        <div className="mx-5 my-0 w-logo">

            <Link to="/" alt={title}>
              <Logo />            
            </Link>

        </div>
      </div>
      <div className="col_Menu">
        <MenuIndex />
      </div>

    </header>
      
  )
}

export default HeaderLayout
