import * as React from "react"
import HeaderLayout from "./header"
import FooterLayout from "./footer"
import { Helmet } from "react-helmet"


const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath


  return (
    <>
    <Helmet>
      <script type="application/ld+json">
       {`
          {
              "@context": "http://schema.org/",
              "@type": "Person",
              "additionalName": "Mary & Maurizio",
              "address": {
                  "addressCountry": "Italy",
                  "email": "contact@maryandmaurizio.com",
              },
              "jobTitle": "Photographer",
          }
        `}
        </script>
      </Helmet>



      <div className="min-h-screen overflow-x-hidden grid" data-is-root-path={isRootPath}>

        <HeaderLayout />

        <main className="grid grid-rows-1 min-h-full">
          {children}
        </main>
        
      </div>
        
      <FooterLayout />

      
    </>
  )
}

export default Layout
